import request from "@/utils/request";

export function getMap(url){
  return request.get('/data-view/proxy', {
    params: {
      url
    }
  })
}

export function countyStat(area_code, code, phase_code) {
  return request.get(`/eduArea/dashboard/county/${code}`, {
    headers: {
      'X-Area-Code': area_code,
    },
    params: {
      phase_code: phase_code
    }
  })
}

export function countySchools(area_code, code, phase_code) {
  return request.get(`/eduArea/dashboard/countySchools/${code}`, {
    headers: {
      'X-Area-Code': area_code,
    },
    params: {
      phase_code: phase_code
    }
  })
}
